import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { ChecklistComponent } from './checklist/checklist.component';
import { GroupComponent } from './checklist/group/group.component';
import { CheckpointComponent } from './checklist/group/checkpoint/checkpoint.component';
import { AttachmentSliderComponent } from './attachment/attachment-slider.component';
import {RouterModule} from '@angular/router';
import { SafeHtmlPipe } from './shared/save-html.pipe';
import {Configuration, IConfiguration} from './shared/checklist.model';

@NgModule({
  declarations: [
    AppComponent,
    ChecklistComponent,
    GroupComponent,
    CheckpointComponent,
    AttachmentSliderComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: 'checklist/:id',
        component: ChecklistComponent,        
      }], {useHash: true})
  ],
  providers: [
    {
      provide: Configuration, useValue: {
        serverBaseUrl: 'https://intg.suva.ch'
      } as IConfiguration
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
