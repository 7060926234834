import {Component, Input, OnInit} from '@angular/core';
import {Attachment, Checkpoint, Choice} from '../../../shared/checklist.model';

@Component({
  selector: 'app-checkpoint',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss']
})
export class CheckpointComponent implements OnInit {
  @Input()
  checkpoint: Checkpoint;
  constructor() { }

  ngOnInit(): void {
  }

  getAttachments(): Attachment[] {
    return this.checkpoint.children.filter(x => x.datasourceTemplate === 'Attachment' && x.fields) as Attachment[];
  }

  getChoices(): Choice[] {
    return this.checkpoint.children.filter(x => x.datasourceTemplate === 'Choice') as Choice[];
  }
}
