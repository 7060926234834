import {Component, Input, OnInit} from '@angular/core';
import {Checkpoint, Group} from '../../shared/checklist.model';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @Input()
  group: Group;

  constructor() {
  }

  ngOnInit(): void {
  }

  get orderedQuestions(): Checkpoint[] {
    return this.group.children.filter(x => x.fields.type === 'Question');
  }

  get notes(): Checkpoint[] {
    return this.group.children.filter(x => x.fields.type === 'Note');
  }

}
