import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Checklist, Configuration, IConfiguration} from './checklist.model';

@Injectable({
  providedIn: 'root'
})
export class OpenContentApiService {

  constructor(private httpClient: HttpClient, @Inject(Configuration) private config: IConfiguration) {
  }

  loadChecklist(id: string): Observable<Checklist> {
    return this.httpClient
      .get<Checklist>(`${this.config.serverBaseUrl}/de-ch/api/OpenContent/Get?item=${id}&token=checklistsPOC&tracking=true`);
  }
}
