import {Component, Input} from '@angular/core';
import {Attachment} from '../shared/checklist.model';

@Component({
  selector: 'app-attachment-slider',
  templateUrl: './attachment-slider.component.html',
  styleUrls: ['./attachment-slider.component.scss']
})
export class AttachmentSliderComponent  {
  @Input()
  attachments: Attachment[];
  indexVisible = 0;

  get attachment(): Attachment {
    return this.attachments[this.indexVisible];
  }

  next() {
    this.indexVisible = Math.min(this.indexVisible + 1, this.attachments.length - 1);
  }

  previous() {
    this.indexVisible = Math.max(this.indexVisible - 1, 0);
  }

  get hasMore() {
    return this.attachments.length - 1 > this.indexVisible;
  }

  get hasPrevious() {
    return this.indexVisible > 0;
  }

}
