<ng-container *ngIf="checkpoint">
  <h3 [innerHTML]="((checkpoint.fields.header || 0) + ' ' + checkpoint.fields.title) | safeHtml"></h3>
  <ng-container *ngFor="let choice of getChoices()">
    <p>
      <input type="radio" [name]="checkpoint.name" value="{{choice.fields.value}}" />
      {{choice.fields.localization}}
    </p>
  </ng-container>
  <app-attachment-slider [attachments]="getAttachments()"></app-attachment-slider>
</ng-container>
