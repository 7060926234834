import {InjectionToken} from '@angular/core';

export const Configuration = new InjectionToken('Configuration');

export interface IConfiguration {
  serverBaseUrl: string;
}

export interface Checklist {
  name: string;
  content: { groups: Group[] };
}

export interface Group {
  name: string;
  fields: { title: string };
  children: Checkpoint[];

}

export interface Checkpoint {
  name: string;
  fields: {
    title: string;
    header: string;
    type: string;
  };
  children: (Choice | Attachment)[];
}

export interface Choice {
  name: string;
  datasourceTemplate: 'Choice';
  fields: {
    localization: string;
    id: string;
    createsReport: boolean;
    value: string;
  };
}

export interface Attachment {
  name: string;
  datasourceTemplate: 'Attachment';
  fields: {
    description: string;
    title: string;
    type: string;
    'shared Image': string;
  };

}
