import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OpenContentApiService} from '../shared/open-content-api.service';
import {Checklist} from '../shared/checklist.model';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {
  checklist: Checklist;

  constructor(private activatedRoute: ActivatedRoute, private service: OpenContentApiService) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      switchMap(params => this.service.loadChecklist(params.id))
    ).subscribe(res => this.checklist = res);
  }
}
